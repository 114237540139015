import React from 'react'
import TemplateFile from './Online Practice Management - User Guide and Data Template1.zip'
import FileUploadPage from './FileUploadPage'
function ExcelPage(props) {// Page to show Download and Upload FIle option 
  return (
    <div className='form' style={{color : "black" , zIndex : "+1", marginLeft : "10%", marginRight : "10%"}}>
        <div>
            <p><h2>Please click on the link below to download the sample excel sheet</h2></p>
        <p>
          <br/>
        {/* <h3><a class="btn btn-primary" href="https://kovaion-my.sharepoint.com/:u:/p/kushagra_bhatnagar/Ec7JOAmwDttEiSX-j-lXa5MBdjv5J9aoYs1bHXzNY1c2mQ?e=NcPPFB" target="_blank" rel="noreferrer noopener">Download</a></h3> Sharepoint Link */}
        <h3><a class="btn btn-primary" href={TemplateFile} download="Online Practice Management - User Guide and Data Template">Download</a></h3> {/*Sharepoint Link*/}
        </p>
        </div>

    <div>
      <p>
        <br/>
         <FileUploadPage name = {props.name} email = {props.email} company = {props.company}/> {/*File Upload Page to show Upload Option */}
      </p>
    </div>
    </div>
  )
}

export default ExcelPage