import React, {useState} from "react";
//import InvitationPage from "./InvitationPage";
import Login from "./Login";
//import InvitationPage from "./InvitationPage";
function Join(props) {

    const [stateBool, setStateBool] = useState(false)//onCLick of Join button change it to loading
    const [condString , setCondString] = useState("") //To store reponse from API gtLoginDetails
    //const [flag, setFlag] = useState(true)
    const [pageState, setPageState] = useState(true)//To go to login page from join page
    
    const [newData , setNewData] = useState({
      tname: "",
      tcompany: "",
      temail: ""
    });//To Capture any change in form Data

    // const [finalData , setFinalData] = useState({
    //   tname: "",
    //   temail: "",
    //   tcompany: "",
    // });

    function handleStateClick(){// Executed when Login Button is clicked
        setPageState(false)
    }

    function handelChange(event){//Executed when user make changes in form data
      const {name, value}= event.target;

      setNewData(prevValue => {
        return {
          ...prevValue,
          [name] : value
          };
        });
      };


      function handelClick(event){//Executed when Join  button is clicked
        
        event.preventDefault()
        setStateBool(true)
        // setFinalData({
        //   tname: newData.name,
        //   temail: newData.email,
        //   tcompany: newData.comp
        // })
          
          
async function updateDataBase(){// Function to make a poast request to getLogindetails with user data
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json")
      //console.log(newData)

      var raw = JSON.stringify(newData);

      var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
      };
  await fetch("https://immilyticsapi-dev.azurewebsites.net/api/getlogindetails", requestOptions)
    .then(response => response.text())
    .then(result => {
      result === "Data inserted successfully" ? sendInviteLink() : console.log("result")
      console.log(result)
      setCondString(result)
      result === "Only business Emails accepted" && alert("Only business Emails accepted")})
    .catch(error => console.log('error', error));
    setStateBool(false)
    //setFlag(false)

}

async function sendInviteLink(){// Function to make a poast request to getLogindetails with user data
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "text/plain");
  
  var raw = "{\r\n\r\n    \"grant_type\" : \"client_credentials\",\r\n\r\n    \"client_id\" : \"7b0df6db-1bdf-42aa-8a22-f11ff054917a\",\r\n\r\n    \"client_secret\":\"m.u8Q~POT0uZc.iU8CVKVlTDMIwr5SNAQYyQwc1W\",\r\n\r\n    \"resource\":\"https://graph.microsoft.com\",\r\n\r\n    \"user_name\": \""+ 
    newData.tname +"\",\r\n\r\n    \"user_email\" : \""+ newData.temail + "\",\r\n\r\n    \"redirect_url\" : \"https://dashboard.immilytics.com\"\r\n\r\n}";
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  fetch("https://immilyticsapi-dev.azurewebsites.net/api/gettoken", requestOptions)
    .then(response => response.text())
    .then(result => {
      //result === "Invitation sent" ? updateDataBase() : console.log("Failed to update database")
      console.log(result)
    })
    .catch(error => console.log('error', error));

}

  updateDataBase(); // First Data has to be updated and if email id don't exist their Invitation will be sent
      }
  return (
    <div>

      {pageState === false && <Login loginWelcome = "Please insert your login credentials"/>}

      {condString === "" && pageState === true && <div>
      <h1>Please, provide your information</h1>
      <form className="form">
        <input name = "tname" type="text" value = {newData.tname} placeholder="Name" onChange = {handelChange}/>
        <input name = "temail" type="email" value = {newData.temail} placeholder="Email id" onChange = {handelChange}/>
        <input name = "tcompany" type="text" value = {newData.tcompany} placeholder="Company Name" onChange = {handelChange}/>
        {stateBool ? <button>    <div>
		    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
		    </div></button> :<button type="submit" onClick = {handelClick}>Join</button>}
      </form>
      <div className="Statement">Already Registered?</div>
      <button onClick={handleStateClick} type="submit" className="btnnn">Login</button>

      
      {/* <div className="Statement">
          {props.formState ? (
            <div>Already Registered?</div>
          ) : (
            <div>New to the Family?</div>
          )}
        </div>
        <button onClick={props.handleStateClick} type="submit" className="btnnn">
          {props.formState ? <div>Log In</div> : <div>Sign Up</div>}
        </button> */}
      
      </div>}
      {/* <ExcelPage name = {newData.tname}/> */}

       {condString === "Empty Data recived" && <div>
      <h1>Information can't be left empty</h1>
      <form className="form">
        <input name = "tname" type="text" value = {newData.tname} placeholder="Name" onChange = {handelChange}/>
        <input name = "temail" type="email" value = {newData.temail} placeholder="Email id" onChange = {handelChange}/>
        <input name = "tcompany" type="text" value = {newData.tcompany} placeholder="Company Name" onChange = {handelChange}/>
        {stateBool ? <button>Loading....</button> :<button type="submit" onClick = {handelClick}>Join</button>}
      </form></div>}

      {condString === "Failed to insert data exception: Data already exists" && <Login loginWelcome = "Your details already exists please try logging in"/>}
      {/* {condString === "Only business Emails accepted" && <Login loginWelcome = "Only Business Emails are accepted"/>} */}

      {condString === "Only business Emails accepted" && pageState === true && <div>
      <h1>Please, provide your information</h1>
      <form className="form">
        <input name = "tname" type="text" value = {newData.tname} placeholder="Name" onChange = {handelChange}/>
        <input name = "temail" type="email" value = {newData.temail} placeholder="Email id" onChange = {handelChange}/>
        <input name = "tcompany" type="text" value = {newData.tcompany} placeholder="Company Name" onChange = {handelChange}/>
        {stateBool ? <button>Loading....</button> :<button type="submit" onClick = {handelClick}>Join</button>}
      </form>
      <div className="Statement">Already Registered?</div>
      <button onClick={handleStateClick} type="submit" className="btnnn">Login</button>
      </div>}



      {/* {condString === "Data inserted successfully" && <ExcelPage name = {newData.tname} email = {newData.temail} company = {newData.tcompany}/>} */}
      {condString === "Data inserted successfully" && <Login loginWelcome = "Accept the invite sent on your email ID to Login with access to Dashboard"/>}

    </div>
  );
}

export default Join;
// export {newData};