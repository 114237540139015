import React,{useState} from "react";
//import FileUploadPage from "./FileUploadPage";
import ExcelPage from "./ExcelPage";
import Join from "./Join";

function Login(props) {
  var OTP_initial
  OTP_initial = Math.floor(1000000 + Math.random() * 9000000);
  const [sentOtp,setSentOtp] = useState(OTP_initial)//Initial value of otp untill any other new otp is generated
  const [otpAlert,setOtpAlert] = useState("")//
  const [flagLogin , setFlagLogin] = useState(true)
  //const [loginWelcome,setLoginWelcome] = useState("Please, insert login credentials")
  //const [flagGeneratingOtp,setFlagGeneratingOtp] = useState(false)//TRue when OTP is being generated
  const [joinState, setJoinState] = useState(false)
  const [generateOtpButton, setGenerateOtpButton] = useState("Generate OTP")
  const [loginDetails, setLoginDetails] = useState(// COntains form data of website
    {tcompany : "",
    temail : "",
    totp : ""}
  )
  const [newData , setNewData] = useState({// Will be used to call API getLogindetails to check wheather 
    tname: "CHECK",
    tcompany: "CHECK",
    temail: ""
  });

  //(props.userExists === "Yes") && setLoginWelcome("Your details already exists please try loging in")

  function handelJoinClick(){
    setJoinState(true)
  }

  function handelChange(event){// To update the form values as user make changes
    const {name, value}= event.target;

    setLoginDetails(prevValue => {
      return {
        ...prevValue,
        [name] : value
        };
      });

      if (name === "temail") {
        setNewData(prevValue => {
          return {
            ...prevValue,
            [name] : value
            };
          })
      }   

    };

  function handelClick(event){//Will be executed when lOGIN button is clicked
    event.preventDefault()
    if (sentOtp.toString() === loginDetails.totp){setFlagLogin(false)}
    else{
      alert("Enter a valid OTP")
    }
  }

  function handelGenerateClick(event){// To Generate OTP when clicked
    event.preventDefault()
    
      setGenerateOtpButton(
      <div>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
       </div>)
      setOtpAlert("")
    
    if(loginDetails.temail==="" || loginDetails.tcompany===""){
      alert("Email and company can't be null")
      setGenerateOtpButton("Generate OTP")
    }else{
      generateOtp()//Will generate otp iff email exists in database
    }
    
  }

  //   {//For checking User in existing Data Base Entering Data in existing data base
     
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   //console.log(newData)

  //   var raw = JSON.stringify(newData);

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: 'follow'
  //   };

  //   async function updateDataBase(){// Trying to update database to check whether Email ID exists or not in the DataBase
  //       await fetch("https://immilyticsapi-dev.azurewebsites.net/api/getlogindetails", requestOptions)
  //       .then(response => response.text())
  //       .then(result => {
  //         if(result === "Failed to insert data exception: Data already exists"){
  //           generateOtp()
  //           //setOtpAlert("User Mail ID exists in database Generating OTP")
  //         }
  //         if(result === "Data inserted successfully"){
  //           setOtpAlert("User doesn't exist. Please join as a New User")
  //           setGenerateOtpButton("Generate OTP")
  //         }
  //         if(result === "Only business Emails accepted")
  //           setOtpAlert("Only business Emails accepted. New to portal? Try joining here")
  //           setGenerateOtpButton("Generate OTP")
  //         })
  //       .catch(error => console.log('error', error));

  // }

  // updateDataBase()}
  

  function generateOtp(){// To generate OTP when Email ID is already verified
    //##########
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    
    var raw = "{\"tEmail\":\""+loginDetails.temail+"\"}";
    //setFlagGeneratingOtp(true)
    // setGenerateOtpButton(
    //   <div class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
    //   <span class="sr-only">Loading...</span></div>
    //  )
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://immilyticsapi-dev.azurewebsites.net/api/otp", requestOptions)
      .then(response => response.text())
      .then(result => {
        if(result === "User Does not exist in database"){

          setOtpAlert("User doesn't exist. Please join as a New User")
          setGenerateOtpButton("Generate OTP")

        }
        else{
          setSentOtp(result)
          setOtpAlert("A One-Time Password (OTP) has been sent to the provided email address. Kindly enter the OTP below to proceed.")
          setGenerateOtpButton("Generate OTP")

        }
        console.log(result)
      })
      .catch(error => console.log('error', error));

}

  return (

    <div>
      {joinState?<Join/>: <div>
        {flagLogin?<div>
        <h1>{props.loginWelcome}</h1>
        <form className="form"
        >
          <input name = "tcompany" type="text" placeholder="Company" value = {loginDetails.tcompany} onChange = {handelChange}/>
          <input name = "temail" type="email" placeholder="Email Id" value = {loginDetails.temail} onChange = {handelChange}/>
          <button type = "button" onClick = {handelGenerateClick} style={{"marginTop" : "5px", "marginBottom" :"0px"}}>{generateOtpButton}</button>
          <div style={{"display": "flex",
                    "justifyContent": "center"}}>
          <p style = {{color : "black", "maxWidth" : "70%", "textAlign" : "center"}}>{otpAlert}
          {otpAlert === "User doesn't exist. Please join as a New User" && <button className="btnnn" onClick = {handelJoinClick} style={{"marginTop" : "0px", "marginBottom" :"0px"}} type = "button">Join</button>}
          {/* {otpAlert === "Only business Emails accepted. New to portal? Try joining here" && <button type = "button" className="btnnn" onClick = {handelJoinClick} style={{"marginTop" : "0px", "marginBottom" :"0px"}}>Join</button>} */}
          </p>
          </div>
          {/* style={{"marginTop" : "0px", "marginBottom" :"5px"}} */}
          {/* {flagGeneratingOtp && <p style={{color : "black"}}>Generating OTP...</p>} */}
          <input name = "totp" type="text" placeholder="Enter OTP received on Mail"  value = {loginDetails.totp} onChange = {handelChange} style={{"marginTop" : "0px", "marginBottom" :"0px"}}/>
          
          <button type="submit" onClick = {handelClick} style={{"marginTop" : "8px", "marginBottom" :"0px"}}>Login</button>

        </form></div>:<ExcelPage company = {loginDetails.tcompany} email = {loginDetails.temail}/>}

    </div>}
    </div>
    
  );
}

export default Login;