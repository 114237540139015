import React, {useState} from 'react';
function FileUploadPage(props){ // To handle Upload

	const [selectedFile, setSelectedFile] = useState();//To store File
	const [isFilePicked, setIsFilePicked] = useState(false);//To know wheather file is selected
	//const [stateUploaded , setStateUploaded ] = useState(false)
	const formData = new FormData();//For calling api to validate file
	const [buttonName,setButtonName] = useState("Upload")//To change name of button in diffrent stages
	const [resultText,setResultText] = useState("")//Final Message
	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};
	async function uploadFile(){//To upload file in azure blob

		formData.append("file", selectedFile);
		formData.append("company_name", props.company);
		formData.append("tEmail", props.email);
		console.log(selectedFile.name)
        // for (const pair of formData.entries()) {
		// 	console.log(`${pair[0]}, ${pair[1]}`);
		//   }		// To console log form data  
		// console.log(props.email)
		// console.log(props.company)


		setButtonName(<div>
		<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
		 Validating...</div>)
		await fetch(
		'https://immilyticsapi-dev.azurewebsites.net/api/azureblob',
		{
			method: 'POST',
			// body : '{"url" : "https://file-examples.com/wp-content/uploads/2017/02/file_example_XLSX_5000.xlsx"}'
			body: formData,
		}
	)

		.then(response => response.text())
		.then(result => {
			setButtonName("Upload")
			
			// setResultText("")
			console.log(result)
			//was not uploaded because of exception cannot unpack non-iterable NoneType object

			if (String(result).includes("was not uploaded because of exception cannot unpack non-iterable NoneType object")){//When uploaded file is of wrong format
				setResultText("Try uploading file again in correct format")
				setSelectedFile()
				setIsFilePicked(false)
			}

			if (result === "Exception occured while getting power bi Link"){
				setResultText("Due to resource limitations in Power BI, we are not able to generate your dashboard at the present time. Please try uploading your file again after some time. Sorry for the inconvenience caused")
				setSelectedFile()
				setIsFilePicked(false)
			}
			if (result === "You have exceeded the number of times an user can upload the report, please contact your business administrator"){
				setResultText("You have exceeded the number of times an user can upload the report, please contact your business administrator")
				setSelectedFile()
				setIsFilePicked(false)
			}
			if (String(result).includes("File uploaded and Mailed")){//WHEN MAIL IS GENERATED
				setResultText("Your Immigration Practice Dashboard has been sent to your registered email address")
				setSelectedFile()
				setIsFilePicked(false)
			}
			if (String(result).includes("Failed to validate")){// FOR VALIDATION FALIURE
				//let msg = String(result).split("[")[1].replace("]","")
				setResultText("Your Immigration Practice Dashboard has been sent to your registered email address")
				//alert(msg)
				setSelectedFile()
				setIsFilePicked(false)
			}


		})
		.catch(error => console.log('error', error));
		//setStateUploaded(true)
	}

	const handleSubmission = (event) => {// Executed when Upload button is clicked
		event.preventDefault()
		if(resultText !== "File has been successfully uploaded. You will receive further updates shortly via email")
		{
		setResultText("")
		isFilePicked&&setResultText("File has been successfully uploaded. You will receive further updates shortly via email")
		isFilePicked&&uploadFile()
		isFilePicked&&setIsFilePicked(!isFilePicked)
		!isFilePicked&&emptyFile()}
	};

	function emptyFile(){
		alert("Please choose a file")
	}

	

	return(
   <div>
			{/* <form>
			<div class="custom-file">
				<input style={{paddingLeft : "50px"}} type="file" name="file" onChange={changeHandler} class="custom-file-input" id="customFile"/>
				<label class="custom-file-label" for="customFile">Choose file</label>
			</div>
			</form> */}
			
			{resultText === "" && <input style={{paddingLeft : "50px"}} type="file" name="file" onChange={changeHandler} />}
			{/* {resultText === "File has been successfully uploaded. You will receive further updates shortly via email" && <input style={{paddingLeft : "50px"}} type="file" name="file" onChange={changeHandler} />} */}
			{resultText !== "" && resultText !== "File has been successfully uploaded. You will receive further updates shortly via email" && <input style={{paddingLeft : "50px"}} type="file" name="file" onChange={changeHandler} />}
            <br/>
            <br/>
			<div>
			<button type = "button" className="btn btn-primary" onClick={handleSubmission}>{buttonName}</button>
			</div>
			<div>
				<p style={{color : "black"}}>{resultText}</p>
			</div>
		</div>
	)
}
export default FileUploadPage